/**
 * @file Confirmation dialog-related text
 */
import { __ } from '@@/bits/intl'
import alertSignSvg from '@@/images/alert_sign.svg'
import armsCrossedInXSvg from '@@/images/arms_crossed_in_x.svg'
import redHeartSvg from '@@/images/red_heart.svg'
import stopHandSvg from '@@/images/stop_hand.svg'
import thinkingFaceSvg from '@@/images/thinking-face.svg'
import { OzConfirmationDialogBoxButtonScheme } from '@@/library/v4/components/OzConfirmationDialogBox.vue'
import type { ConfirmationDialogPayload } from '@@/pinia/global_confirmation_dialog'

export const ALERT_ICON: Pick<ConfirmationDialogPayload, 'iconSrc' | 'iconAlt'> = {
  iconSrc: alertSignSvg,
  iconAlt: __('Alert Sign'),
}

export const CONFIRM_DISCARD_CHANGES: Omit<ConfirmationDialogPayload, 'afterConfirmActions'> = {
  title: __('You have unsaved changes'),
  body: __('Are you sure you want to discard them and close this panel?'),
  confirmButtonText: __('Discard and close'),
  cancelButtonText: __('Keep working'),
}

export const CONFIRM_DELETE_POST: Omit<ConfirmationDialogPayload, 'afterConfirmActions'> = {
  ...ALERT_ICON,
  isCodeProtected: false,
  title: __('Delete post?'),
  body: __('Are you sure you want to delete this post? This cannot be undone!'),
  confirmButtonText: __('Delete'),
  cancelButtonText: __('Nevermind'),
  buttonScheme: OzConfirmationDialogBoxButtonScheme.Danger,
}

export const CONFIRM_DELETE_CUSTOM_TEXT_FIELD: Omit<ConfirmationDialogPayload, 'afterConfirmActions' | 'title'> = {
  ...ALERT_ICON,
  isCodeProtected: false,
  confirmButtonText: __('Delete'),
  cancelButtonText: __('Nevermind'),
  buttonScheme: OzConfirmationDialogBoxButtonScheme.Danger,
}

export const CONFIRM_DELETE_DRAFT: Omit<ConfirmationDialogPayload, 'afterConfirmActions'> = {
  ...ALERT_ICON,
  isCodeProtected: false,
  title: __('Delete draft?'),
  body: __('Are you sure you want to delete this draft? This cannot be undone!'),
  confirmButtonText: __('Delete'),
  cancelButtonText: __('Nevermind'),
  buttonScheme: OzConfirmationDialogBoxButtonScheme.Danger,
}

export const CONFIRM_DISCARD_SETTING_CHANGES: Omit<ConfirmationDialogPayload, 'afterConfirmActions'> = {
  ...CONFIRM_DISCARD_CHANGES,
  confirmButtonText: __('Save and exit'),
  discardButtonText: __('Exit without saving'),
}

export const CONFIRM_CANCEL_UPLOAD_SETTING: Omit<ConfirmationDialogPayload, 'afterConfirmActions'> = {
  title: __('Your upload is still in progress'),
  body: __('Are you sure you want to cancel uploading?'),
  buttonScheme: OzConfirmationDialogBoxButtonScheme.Danger,
  confirmButtonText: __('Cancel upload'),
  cancelButtonText: __('Nevermind'),
}

export const DELETE_WALL_CONFIRMATION_PARAMS: Omit<ConfirmationDialogPayload, 'afterConfirmActions'> = {
  isCodeProtected: true,
  title: __('Delete this padlet?'),
  body: __('This action cannot be undone.'),
  confirmButtonText: __('Delete'),
  cancelButtonText: __('Nevermind'),
  buttonScheme: OzConfirmationDialogBoxButtonScheme.Danger,
  shouldFadeIn: false,
}

export const PERMANENTLY_DELETE_WALL_CONFIRMATION_PARAMS: Omit<ConfirmationDialogPayload, 'afterConfirmActions'> = {
  isCodeProtected: false,
  title: __('Permanently delete?'),
  body: __('Your padlet will be permanently deleted. You will not be able to undo this action.'),
  confirmButtonText: __('Delete'),
  cancelButtonText: __('Cancel'),
  buttonScheme: OzConfirmationDialogBoxButtonScheme.Danger,
  shouldFadeIn: false,
}

export const THINKING_FACE_ICON: Pick<ConfirmationDialogPayload, 'iconSrc' | 'iconAlt'> = {
  iconSrc: thinkingFaceSvg,
  iconAlt: __('Thinking Face'),
}

export const ARMS_CROSSED_IN_X_ICON: Pick<ConfirmationDialogPayload, 'iconSrc'> = {
  iconSrc: armsCrossedInXSvg,
}

export const STOP_HAND_ICON: Pick<ConfirmationDialogPayload, 'iconSrc'> = {
  iconSrc: stopHandSvg,
}

export const CONFIRM_MODERATION_REJECT_POST: Omit<ConfirmationDialogPayload, 'afterConfirmActions'> = {
  ...ARMS_CROSSED_IN_X_ICON,
  isCodeProtected: false,
  title: __('Reject this post?'),
  body: __("Are you sure you want to reject this post? It will be moved back to the writer's drafts."),
  confirmButtonText: __('Reject'),
  cancelButtonText: __('Nevermind'),
  buttonScheme: OzConfirmationDialogBoxButtonScheme.Danger,
}

export const CONFIRM_MODERATION_REJECT_COMMENT: Omit<ConfirmationDialogPayload, 'afterConfirmActions'> = {
  ...ARMS_CROSSED_IN_X_ICON,
  isCodeProtected: false,
  title: __('Reject this comment?'),
  body: __('Are you sure you want to reject this comment? It will be deleted permanently.'),
  confirmButtonText: __('Reject'),
  cancelButtonText: __('Nevermind'),
  buttonScheme: OzConfirmationDialogBoxButtonScheme.Danger,
}

export const RED_HEART_ICON: Omit<ConfirmationDialogPayload, 'afterConfirmActions' | 'title'> = {
  iconSrc: redHeartSvg,
}
export const SELF_HARM_POST_ALERT: Omit<ConfirmationDialogPayload, 'afterConfirmActions'> = {
  ...RED_HEART_ICON,
  isCodeProtected: false,
  title: __('Is there something we can do to help?'),
  body: __(
    'Based on your post it seems like you might be going through a difficult time. If you’d like to talk with someone, consider reaching out for free, confidential support.',
  ),
  confirmButtonText: __('Find a helpline'),
  cancelButtonText: __('Not now'),
  forceFullWidthButtons: true,
}

export const SELF_HARM_COMMENT_ALERT: Omit<ConfirmationDialogPayload, 'afterConfirmActions'> = {
  ...RED_HEART_ICON,
  isCodeProtected: false,
  title: __('Is there something we can do to help?'),
  body: __(
    'Based on your comment it seems like you might be going through a difficult time. If you’d like to talk with someone, consider reaching out for free, confidential support.',
  ),
  confirmButtonText: __('Find a helpline'),
  cancelButtonText: __('Not now'),
  forceFullWidthButtons: true,
}

export const CONFIRM_TURN_OFF: Omit<ConfirmationDialogPayload, 'afterConfirmActions' | 'title'> = {
  ...ALERT_ICON,
  confirmButtonText: __('Turn off'),
  cancelButtonText: __('Nevermind'),
  buttonScheme: OzConfirmationDialogBoxButtonScheme.Danger,
  forceFullWidthButtons: true,
}

export default {
  ALERT_ICON,
  CONFIRM_DISCARD_CHANGES,
  CONFIRM_DELETE_POST,
  CONFIRM_CANCEL_UPLOAD_SETTING,
  DELETE_WALL_CONFIRMATION_PARAMS,
  THINKING_FACE_ICON,
  ARMS_CROSSED_IN_X_ICON,
  CONFIRM_MODERATION_REJECT_POST,
  CONFIRM_MODERATION_REJECT_COMMENT,
  RED_HEART_ICON,
  SELF_HARM_POST_ALERT,
  SELF_HARM_COMMENT_ALERT,
  CONFIRM_TURN_OFF,
}
